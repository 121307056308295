<div class="alert alert-info message-container" *ngIf="isNewAppVersionAvailable">
    <div class="d-flex align-items-center">
      <div>
        <span class="pr-2">New App Version</span>
        <small class="pr-2">
            Una nueva versión de la aplicación está disponible. Actualice la ventana de su navegador o haga clic en el botón Actualizar.
        </small>
      </div>
      <div>
        <button (click)="refreshApp()" class="btn btn-primary btn-sm rounded-pill">
          <span class="text-nowrap">Refresh</span>
        </button>
      </div>
    </div>
  </div>
  

<nav class="bg-light sticky-top">
    <nav class="navbar navbar-expand-lg navbar-light ">
        <div class="container-fluid">
            <button type="menu" class="navbar-toggler" (click)="toggle = !toggle">
                <i class="bi bi-list"></i>
            </button>
            <a class="navbar-brand">
                <img src="assets/img/logo_sofofa.svg" class="logo-cabecera pull-left" style="margin-top:-7px;">
            </a>
            <p class="top otic title m-0">Mantenedor<br/>de Empresas</p>
            <div style="color: white;font-size: 12px;padding-left: 10px;">
                <span>{{versionHash}}</span>
              </div>

              <div class="col-1" *ngIf="!versionHash">
                <div class="row" style="color: white;font-size: 12px;padding-left: 10px;">
                    <span class="me-3">Cargo: {{perfil.cargo ? perfil.cargo : '0'}}</span>
                    <span>Id: {{perfil.cargoId ? perfil.cargoId : '0'}}</span>
                  </div>
              </div>

              <div class="col-1" *ngIf="!versionHash">
                <div class="row" style="color: white;font-size: 12px;padding-left: 10px;">
                    <span class="me-3">Area: {{perfil.area ? perfil.area : '0'}}</span>
                    <span>Id: {{perfil.areaId ? perfil.areaId : '0'}}</span>
                  </div>
              </div>
              <div class="col-1" *ngIf="!versionHash">
                <div class="row" style="color: white;font-size: 12px;padding-left: 10px;">
                    <button (click)="reLogear()">Re Logear</button>
                  </div>
              </div>

            <li class="nav-item d-sm-none" style="display: inline-flex; border-right: 1px solid #DBDBDB;">
                <span class="badge">
                    <img src="assets/icons/user.svg" alt="user">
                </span>
            </li>
            <div class="collapse navbar-collapse justify-content-end">
                <ul class="navbar-nav justify-content-end d-flex align-items-center" style="margin-right: 10px;">
                    <li class="nav-item">
                        <button class="btn position-relative" (click)="openNotificaciones()" [title]="notificacionesNoLeidas > 0 ? notificacionesNoLeidas + ' nuevas notidficaciones' : ''">
                            <img src="assets/icons/bell.svg" alt="notification">
                            @if (notificacionesNoLeidas > 0) {
                                <span class="badge text-bg-warning text-light">{{notificacionesNoLeidas}}</span>
                            }
                        </button>
                    </li>
                    <li class="nav-item" style="display: flex;align-items: center; border-right: 1px solid #DBDBDB;">
                        <span class="badge">
                            <img src="assets/icons/user.svg" alt="user">
                        </span>
                        <span style="margin-right: 10px;"> {{userInfo?.nombres}}</span>
                    </li>
                    <li class="nav-item">
                        <button class="btn" (click)="logout()">
                            <img src="assets/icons/exit.svg" alt="logout">
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
    <div class="menu-btn position-relative shadow d-none d-lg-block">
            <button id="toggle-menu" class="btn btn-primary" [ngClass]="{'active': toggle}" (click)="toggle = !toggle">MENÚ <i class="bi bi-list"></i></button>
    </div>
    <aside [ngClass]="toggle ? 'show' : 'collapse'">
        <div mouseOut (outsideClick)="toggle = false" class="sidebar" (mouseleave)="toggle = false">
            <ul class="navbar-nav ms-3">
                <div class="d-flex" *ngFor="let route of routes; let i">
                  <ng-container *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]">
                    <ng-container *ngIf="route.cargo?.includes(i) && userInfo?.cargo?.areaId == i">
                      <a [routerLink]="route.link" class="nav-link">
                        <i class="bi" [ngClass]="route.icon"></i>
                        {{route.descripcion}}
                      </a>
                    </ng-container>
                  </ng-container>

                </div>
            </ul>
        </div>
    </aside>
</nav>
